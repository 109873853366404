import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/kontenery'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import H from '../../components/H'
import { s, sTextGradient } from '../../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import alt from '../../content/alt'

const Kontenery = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kontenery.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      imagepl: file(
        absolutePath: { regex: "/images/containers/containers_pl.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      imageen: file(
        absolutePath: { regex: "/images/containers/containers_en.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
      imageua: file(
        absolutePath: { regex: "/images/containers/containers_ua.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
      ua: 'ПЕРЕГЛЯНУТИ',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='bottom'
      />
      <Main h={1} title={main.title[lang]} body={main.desc[lang]}>
        <H h={2} css={[sTextGradient, sHeadline]}>
          {main.contarinerTypes[lang]}
        </H>
      </Main>
      <GatsbyImage
        image={getImage(query[`image${lang}`].childImageSharp)}
        alt={intro.title[lang] + alt}
        css={sImage}
      />
    </Layout>
  )
}

const sHeadline = {
  margin: '4rem 0',
  fontSize: '2rem',
  [s.xs]: {
    fontSize: '1.5rem',
  },
}

const sImage = {
  width: '100vw',
  height: '100%',
}

export default Kontenery
